import React from 'react';
import { PuffLoader } from 'react-spinners';
import './Spinner.css';

const Spinner = () => (
    <div className="spinner-overlay">
        <PuffLoader color="#fa4454" loading={true} />
        <div className="spinner-text">
            Pulling data, this will only take a moment...
        </div>
        <div className="spinner-tip">
            <strong>Fun Fact:</strong> Killjoy's Nanoswarm, KAY/O's Fragment, Gekko's Moshpit, and Deadlock's GravNet all have the exact same projectile trajectory. That means you can re-use lineups among these abilities!
        </div>
    </div>
);

export default Spinner;
