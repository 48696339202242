import React, { Suspense, lazy, useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation, Link, Navigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';
import Spinner from './Spinner'; // Import Spinner component

// Dynamically import components
const CrosshairGallery = lazy(() => import('./CrosshairGallery'));
const Sidebar = lazy(() => import('./Sidebar'));
const MapSelection = lazy(() => import('./MapSelection'));
const MapDetailView = lazy(() => import('./MapDetailView'));
const UserLineupSubmission = lazy(() => import('./UserLineupSubmission'));
const MobileNotSupported = lazy(() => import('./MobileNotSupported'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const TermsOfService = lazy(() => import('./TermsOfService'));
const MobileNavBar = lazy(() => import('./MobileNavBar'));
const ImageSlideshow = lazy(() => import('./ImageSlideshow'));

const AppContent = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const location = useLocation();

  const shouldShowMobileNavBar = ['/valorant', '/valorant/lineups', '/valorant/crosshairs', '/valorant/submit_lineup'].includes(location.pathname);

  return (
    <div className="App">
      {!isMobile && <Suspense fallback={<Spinner />}><Sidebar /></Suspense>}
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Navigate to="/valorant" replace />} />
          <Route path="/valorant" element={<Home />} />
          <Route path="/valorant/crosshairs" element={<Suspense fallback={<Spinner />}><CrosshairGallery /></Suspense>} />
          <Route path="/valorant/lineups" element={<Suspense fallback={<Spinner />}><MapSelection /></Suspense>} />
          <Route path="/valorant/lineups/:mapId" element={<Suspense fallback={<Spinner />}><MapDetailView /></Suspense>} />
          <Route path="/valorant/submit_lineup" element={isMobile ? <MobileNotSupported /> : <Suspense fallback={<Spinner />}><UserLineupSubmission /></Suspense>} />
          <Route path="/privacy-policy" element={<Suspense fallback={<Spinner />}><PrivacyPolicy /></Suspense>} />
          <Route path="/terms-of-service" element={<Suspense fallback={<Spinner />}><TermsOfService /></Suspense>} />
          {/* Catch-all route */}
          <Route path="*" element={<Navigate to="/valorant" replace />} />
        </Routes>
      </div>
      {isMobile && shouldShowMobileNavBar && <Suspense fallback={<Spinner />}><MobileNavBar /></Suspense>}
    </div>
  );
};

function App() {
  return (
    <Router>
      <Suspense fallback={<Spinner />}>
        <AppContent />
      </Suspense>
    </Router>
  );
}

const Home = () => {
  const crosshairPhotos = [
    { src: '/assets/crosshair_photos/photo1.webp', name: 'Tarik' },
    { src: '/assets/crosshair_photos/photo2.webp', name: 'TenZ' },
    { src: '/assets/crosshair_photos/photo3.webp', name: 'yay' },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % crosshairPhotos.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [crosshairPhotos.length]);

  const sectionStyle = {
    lineups: {
      background: "url('/assets/agents.webp') no-repeat center center",
      backgroundSize: 'cover',
      backgroundPosition: 'center 0%' // Adjust this value to lower the image if necessary
    },
    crosshairs: {
      background: "url('/assets/banners/omen_banner.webp') no-repeat center center",
      backgroundSize: 'cover',
      backgroundPosition: 'center 0%', // Adjust this value to lower the image if necessary
    },
    submitLineups: {
      background: "url('/assets/banners/sage_banner.webp') no-repeat center center",
      backgroundSize: 'cover',
      backgroundPosition: 'center 0%', // Adjust this value to lower the image if necessary
    },
  };

  const lineupImages = [
    '/assets/LineupsSS1.webp',
    '/assets/LineupsSS2.webp',
    '/assets/LineupsSS3.webp'
  ];

  return (
    <div className="home-container">
      <Helmet>
        <title>VALORANT Lineups and Crosshairs - Lineup Wizard</title>
        <link rel="canonical" href="https://lineupwizard.gg/valorant" />
        <meta
          name="description"
          content="Lineup Wizard is your ultimate resource for VALORANT lineups and crosshairs. Our site is quick and easy to use, offering expertly curated lineups, and crosshairs used by the world's best players."
        />
        <meta name="keywords" content="VALORANT lineups, VALORANT crosshairs, VALORANT setups, lineup wizard, lineupwizard gg, lineupwizard, lineups, VALORANT" />
        <meta property="og:title" content="VALORANT Lineups and Crosshairs - Lineup Wizard" />
        <meta property="og:description" content="LineupWizard.gg is your ultimate resource for VALORANT lineups and crosshairs. Our site is quick and easy to use, offering expertly curated lineups, and crosshairs used by the world's best players." />
        <meta property="og:url" content="https://lineupwizard.gg/valorant" />
        <meta property="og:image" content="https://lineupwizard.gg/assets/logos/logo.svg" />
        <meta property="twitter:title" content="LineupWizard.gg - VALORANT Lineups and Crosshairs" />
        <meta property="twitter:description" content="LineupWizard.gg is your ultimate resource for VALORANT lineups and crosshairs. Our site is quick and easy to use, offering expertly curated lineups, and crosshairs used by the world's best players." />
        <meta property="twitter:image" content="https://lineupwizard.gg/assets/logos/logo.svg" />
      </Helmet>
      <div className="content">
        <div className="logo-container">
          <img src="/assets/logos/logo.svg" alt="Lineup Wizard Logo" className="logo" />
          <div className="title-text">
            <h1>LineupWizard.gg</h1>
            <h2>
              <img src="/assets/logos/valorant2.svg" alt="Valorant Logo" className="valorant-logo-header" />
              VALORANT Lineups
            </h2>
          </div>
        </div>
        <p className="ultimate-resource">
          Your ultimate resource for VALORANT lineups and crosshairs.
        </p>
        <div className="sections">
          <div className="section lineups" style={sectionStyle.lineups}>
            <div className="overlay"></div>
            <h2>
              <img src="/assets/logos/valorant2.svg" alt="Valorant Logo" className="valorant-logo-section" />
              VALORANT Lineups
            </h2>
            <p>
              Explore detailed lineups for every map and agent in VALORANT.
            </p>
            <ImageSlideshow images={lineupImages} />
            <Link to="/valorant/lineups" className="cta-button">Explore Lineups</Link>
          </div>
          <div className="section crosshairs" style={sectionStyle.crosshairs}>
            <div className="overlay"></div>
            <h2>Crosshairs</h2>
            <p>Browse and copy crosshairs used by top VALORANT players.</p>
            <div className="slideshow-container">
              <div className="slideshow-caption">{crosshairPhotos[currentIndex].name}</div>
              <img src={crosshairPhotos[currentIndex].src} alt="Crosshair" className="slideshow-image" />
            </div>
            <Link to="/valorant/crosshairs" className="cta-button">Browse Crosshairs</Link>
          </div>
          <div className="section submit-lineups" style={sectionStyle.submitLineups}>
            <div className="overlay"></div>
            <h2>Submit Lineups</h2>
            <p>Submit and share your favorite lineups with the community.</p>
            <div className="submit-lineup-image-container">
              <img src="/assets/submit_lineup.webp" alt="Lineup Submission" className="submit-lineup-image" />
            </div>
            <Link to="/valorant/submit_lineup" className="cta-button">Submit Your Lineup</Link>
          </div>
        </div>
        <div className="future-support">
          <h2>Counter-Strike Support</h2>
          <p>Counter-Strike lineup support coming in the future!</p>
        </div>
        {/* Hidden internal links for SEO */}
        <div style={{ display: 'none' }}>
          <Link to="/valorant/lineups">Explore Lineups</Link>
          <Link to="/valorant/crosshairs">Browse Crosshairs</Link>
          <Link to="/valorant/submit_lineup">Submit Your Lineup</Link>
        </div>
      </div>
    </div>
  );
};

export default App;
